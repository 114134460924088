export default [
  {
    path: '/creative-studio',
    name: 'creative-studio',
    component: () => import('@/views/canvaDesigns/index.vue'),
    meta: {
      pageTitle: 'Creative Studio',
      breadcrumb: [
        {
          text: 'Creative Studio',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]

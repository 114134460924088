import Vue from 'vue'
import { showToast } from '@/common/global/functions'

export default {
  namespaced: true,
  state: {
    selectedDevices: [],
  },
  mutations: {
    SET_SELECTED_DEVICES(state, payload) {
      state.selectedDevices = payload
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async getAllPmpCampaignsByUser({ commit }, { limit, offset }) {
      try {
        const response = await Vue.prototype.$axios.get(`/pmp-ads?limit=${limit}&offset=${offset}&search_query=`)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw new Error(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getAllUserPmpCampaignsByAdmin({ commit }, { groupId, limit, offset }) {
      try {
        const response = await Vue.prototype.$axios.get(`/admin/pmp-ad/user-has-group/${groupId}?limit=${limit}&offset=${offset}&search_query=`)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw new Error(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createNewPMPAdsByAdmin({ commit }, { postData, groupId }) {
      try {
        const response = await Vue.prototype.$axios.post('/admin/pmp-ad', postData, { headers: { userHasGroupId: groupId } })
        if (response.data.statusCode === 201) {
          showToast('Add PMP Ad', 'PMP Ad has been added successfully!')
        }
        return true
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw error
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createNewPMPAdsByUser({ commit }, { postData }) {
      try {
        const response = await Vue.prototype.$axios.post('/pmp-ads', postData)
        if (response.data.statusCode === 201) {
          showToast('Add PMP Ad', 'PMP Ad has been added successfully!')
        }
        return true
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw error
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getAvailableDevicesByAdmin({ commit }, { postData }) {
      try {
        const response = await Vue.prototype.$axios.post('/admin/device/get-available-devices', postData)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw error
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getAvailableDevicesByUser({ commit }, { postData }) {
      try {
        const response = await Vue.prototype.$axios.post('/user/device/get-available-devices', postData)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw error
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getOnePmpAdByUser({ commit }, { id }) {
      try {
        const response = await Vue.prototype.$axios.get(`/pmp-ads/${id}`)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw error
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getOnePmpAdByAdmin({ commit }, { id, userHasGroupId }) {
      try {
        const response = await Vue.prototype.$axios.get(`/admin/pmp-ad/find/${id}`, { headers: { userHasGroupId } })
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw error
      }
    },
    // eslint-disable-next-line no-unused-vars
    async editPmpAdsDataByAdmin({ commit }, { PmpAdId, postData, userHasGroupId }) {
      try {
        const response = await Vue.prototype.$axios.put(`admin/pmp-ad/${PmpAdId}`, postData, { headers: { userHasGroupId } })
        if (response.data.statusCode === 200) {
          showToast('Edit PMP Ad', 'PMP Ad has been updated successfully!')
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw error
      }
    },
    // eslint-disable-next-line no-unused-vars
    async editPmpAdsDataByUser({ commit }, { PmpAdId, postData, userHasGroupId }) {
      try {
        const response = await Vue.prototype.$axios.put(`/pmp-ads/${PmpAdId}`, postData)
        if (response.data.statusCode === 200) {
          showToast('Edit PMP Ad', 'PMP Ad has been updated successfully!')
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw error
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getAllPmpCampaignsOfAllUsersByAdmin({ commit }, { limit, offset, status }) {
      try {
        const response = await Vue.prototype.$axios.get(`/admin/pmp-ad/get-all-pmp-ads?limit=${limit}&offset=${offset}&search_query=&status=${status}`)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw new Error(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async rejectPmpAdByAdmin({ commit }, { id }) {
      try {
        const response = await Vue.prototype.$axios.put(`/admin/pmp-ad/reject-pmp-ad/${id}`)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw new Error(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async approvePmpAdByAdmin({ commit }, { id }) {
      try {
        const response = await Vue.prototype.$axios.put(`/admin/pmp-ad/approve-pmp-ad/${id}`)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw new Error(error)
      }
    },

  },
  getters: {
    getSelectedDevices: state => state.selectedDevices,
  },
}

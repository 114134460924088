export default [
  {
    path: '/pmp-ads',
    name: 'pmp-ads',
    component: () => import('@/views/pmpAds/index.vue'),
    meta: {
      pageTitle: 'PMP Ads',
      breadcrumb: [
        {
          text: 'PMP Ads',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/pmp-ads/add',
    name: 'pmp-ads-add',
    component: () => import('@/views/pmpAds/CreatePmpAds.vue'),
    meta: {
      navActiveLink: 'pmp-ads',
      pageTitle: 'Add PMP Ad',
      breadcrumb: [
        {
          text: 'PMP Ads',
          to: '/pmp-ads',
        },
        {
          text: 'Add PMP Ad',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/pmp-ads/edit-pmp-ads/:id',
    name: 'edit-pmp-ads',
    component: () => import('@/components/pmp-ads/EditPmpAds.vue'),
    meta: {
      navActiveLink: 'pmp-ads',
      pageTitle: 'Edit PMP Ad',
      breadcrumb: [
        {
          text: 'PMP Ads',
          to: '/pmp-ads',
        },
        {
          text: 'Edit PMP Ad',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/pmp-ads/view/:id',
    name: 'view-pmp-ads',
    component: () => import('@/views/pmpAds/ViewPmpAd.vue'),
    meta: {
      navActiveLink: 'pmp-ads',
      pageTitle: 'View PMP Ad',
      breadcrumb: [
        {
          text: 'PMP Ads',
          to: '/pmp-ads',
        },
        {
          text: 'View PMP Ad',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]

import Vue from 'vue'
import { showToast } from '@/common/global/functions'

export default {
  namespaced: true,
  state: {
    canvaCode: null,
    accessToken: null,
    listOfDesigns: null,
    exportURL: null,
    isValid: false,
    tokenRevoked: false,
  },
  mutations: {
    SET_CANVA_CODE(state, payload) {
      state.canvaCode = payload
    },
    SET_ACCESS_TOKEN(state, payload) {
      state.accessToken = payload
    },
    SET_LIST_OF_DESIGNS(state, payload) {
      state.listOfDesigns = payload
    },
    SET_EXPORTED_URLS(state, payload) {
      state.exportURL = payload
    },
    SET_IS_VALID(state, payload) {
      state.isValid = payload
    },
    SET_TOKEN_REVOKED(state, payload) {
      state.isValid = payload
    },
  },
  actions: {
    setUser: ({ commit }, user) => {
      commit('SET_USER', user)
    },
    setSelectedGroupId: ({ commit }, id) => {
      commit('SET_SELECTED_GROUP_ID', id)
    },
    async getCanvaCode({ commit }) {
      try {
        const response = await JSON.parse(localStorage.getItem('canvaCode'))
        commit('SET_CANVA_CODE', response)
        return response
      } catch (error) {
        console.error(error)
        return error
      }
    },
    async getAccessTokenExchangedAgainstCanvaCode({ commit }, { canvaCode }) {
      try {
        const response = await Vue.prototype.$axios.get(`auth/canva/callback?code=${canvaCode}`)
        commit('SET_ACCESS_TOKEN', response.data.data)
        localStorage.setItem('canva-token', `${JSON.stringify(response.data.data)}`)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },
    async refreshToken({ commit }, { refreshToken }) {
      try {
        const response = await Vue.prototype.$axios.get(`auth/refresh-token?refreshToken=${refreshToken}`)
        localStorage.setItem('canva-token', JSON.stringify(response.data.data))
        commit('SET_ACCESS_TOKEN', response.data.data)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },
    async introspectToken({ commit }, { accessToken }) {
      try {
        const response = await Vue.prototype.$axios.get(`auth/introspectToken?accessToken=${accessToken}`)
        commit('SET_IS_VALID', response.data.data)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },
    async revokeAccess({ commit }, { accessToken }) {
      try {
        const response = await Vue.prototype.$axios.get(`auth/revokeAccess?accessToken=${accessToken}`)
        if (response.status === 200) {
          commit('SET_TOKEN_REVOKED', true)
          return true
        } return false
      } catch (error) {
        console.error(error)
        return false
      } finally {
        localStorage.removeItem('canva-token')
        localStorage.removeItem('canvaCode')
        showToast('Success', 'Successfully Logged out of Canva', 'success')
      }
    },
    async getDesigns({ commit }, { accessTokenUser }) {
      try {
        const response = await Vue.prototype.$axios.get(`auth/design?accessToken=${accessTokenUser}`)
        commit('SET_LIST_OF_DESIGNS', response.data.data)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },
    async downloadDesign({ commit }, {
      designID, accessTokenUser, typeOfMedia, quality,
    }) {
      try {
        const response = await Vue.prototype.$axios.get(`/auth/export/${designID}?accessToken=${accessTokenUser}&typeOfMedia=${typeOfMedia}&quality=${quality}`)
        commit('SET_EXPORTED_URLS', response.data)
        return true
      } catch (error) {
        showToast('Error', error.response.data.error.message, 'danger')
        throw error.response.data
      }
    },
  },
  getters: {
    getCanvaCode: state => state.canvaCode,
    getAccessToken: state => state.accessToken,
    getListOfDesigns: state => state.listOfDesigns,
    getExportURL: state => state.exportURL,
    getIsValid: state => state.isValid,
  },
}

const MemberPermissions = {
  PROFILE: 'profile',
  MEDIA: 'media',
  DEVICES: 'devices',
  INTERNAL_NETWORK_ADS: 'banner-campaigns',
  TARGET_ADS: 'target-ads',
  PMP_ADS: 'pmp-ads',
  SITE: 'one-site',
  REPORT: 'report',
}
export default MemberPermissions

export default [
  {
    path: '/pmpAdsList',
    name: 'pmpAdsList',
    component: () => import('@/views/admin/pmpAdsList/index.vue'),
    meta: {
      pageTitle: 'Pmp Ad Campaign List',
      breadcrumb: [
        {
          text: 'Pmp Campaign List',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/pmpAdsList/view-ads/:id',
    name: 'user-pmp-ads-view',
    component: () => import('@/views/pmpAds/ViewPmpAd.vue'),
    meta: {
      pageTitle: 'View Campaign',
      breadcrumb: [
        {
          isDynamic: true,
          text: 'Pmp Campaign List',
          to: '/pmpAdsList',
        },
        {
          text: 'View Campaign',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
